<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
          <i>></i>
          <a href="javascript:;">VR体验</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="shell">
              <img class="v_1" src="../../assets/vrImg/v_0.png" alt="" />
              <div class="v_2">什么是720°VR全景系统</div>
              <div class="v_3">
                基于3D+VR+AI技术构建，强大的VR全景营销系统。提供OEM模式，为你搭建VR全景平台，一次买断，使用终生，不再是传统VR全景加盟代理模式。打造集全景展示、广告营销、企业定制应用，符合每一个客户专属品牌的VR全景展示平台。
              </div>
            </div>
            <div class="v_5">项目展示</div>
            <div class="v_4">
              <div>
                <img
                  @click="toVrPage(1)"
                  src="../../assets/vrImg/v_1.png"
                  alt=""
                />
                <p>某公司应急疏散线路展示</p>
              </div>
              <div>
                <img
                  @click="toVrPage(2)"
                  src="../../assets/vrImg/v_2.png"
                  alt=""
                />
                <p>某公司化工实训实习工厂半实物设备场景</p>
              </div>
              <div>
                <img
                  @click="toVrPage(3)"
                  src="../../assets/vrImg/v_3.png"
                  alt=""
                />
                <p>某银行总部大楼安全质量标准化展示</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "wrongAuthenticatePage_VRfictitious",
  components: {},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    // 点击跳转
    toVrPage(type) {
      if (type == 1) {
        window.open("https://anzhi.bjsrxx.com/banner/vr_1.html");
      }
      if (type == 2) {
        window.open("https://anzhi.bjsrxx.com/banner/vr_2.html");
      }
      if (type == 3) {
        window.open("https://anzhi.bjsrxx.com/banner/vr_3.html");
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.framePage .framePage-body {
  background: transparent;
}
.shell {
    background: #fff;
    padding-bottom: 30px;
}
.v_1 {
  width: 100%;
}
.v_2 {
  text-align: center;
  font-size: 32px;
  margin-top: 16px;
}
.v_5 {
  text-align: center;
  font-size: 32px;
  margin-top: 10px;
}
.v_3 {
  width: 60%;
  margin: auto;
  text-indent: 2em;
  margin-top: 20px;
  color: #666666;
}
.v_4 {
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 20px;
  div {
    width: 33%;
    border: 4px solid #fff;
    border-radius: 6px;
    transition: all 0.3s linear;
    position: relative;
    img {
      width: 100%;
    }
    p {
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: center;
      width: 100%;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      background: rgba(0, 0, 0, 0.4);
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      // transition: all 1s;
      transition: all 0.5s ease 0.1s;
      z-index: 2;
    }
  }
}
</style>